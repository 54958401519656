import { boot } from 'quasar/wrappers';
import axios, { AxiosInstance } from 'axios';
import { useUserStore } from 'stores/user';
import { Notify } from 'quasar';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
    $api: AxiosInstance;
  }
}

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
const api = axios.create({
  baseURL: (process.env.BACKEND_SRV as string) + process.env.API_URL_PREFIX,
});

api.interceptors.request.use(async (req) => {
  const userStore = useUserStore();
  const token = userStore.token;
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
    //console.log('Adding ID token: ' + token);
  }
  return req;
});

export default boot(({ app, router }) => {
  api.interceptors.response.use(
    async (response) => {
      return response;
    },
    async (error) => {
      if (401 === error.response?.status) {
        router.push({ path: '/login' });
      } else if (403 === error.response?.status) {
        const user = useUserStore();
        await user.reload_user();
        router.push({ path: '/profile' });
      } else if (error.response.status === 404) {
        Notify.create({
          type: 'negative',
          position: 'top',
          message: 'Запрашиваемая страница не найдена.',
          timeout: 0,
          multiLine: true,
          actions: [
            {
              outline: true,
              dense: true,
              icon: 'chevron_left',
              label: 'Назад',
              color: 'yellow',
              handler: () => {
                router.back();
              },
            },
          ],
        });
      } else {
        Notify.create({
          type: 'negative',
          position: 'top',
          message:
            'При загрузке данных произошла ошибка. Попробуйте обновить страницу.',
          timeout: 0,
          multiLine: true,
          actions: [
            {
              outline: true,
              dense: true,
              icon: 'chevron_left',
              label: 'ВЕРНУТЬСЯ',
              color: 'white',
              handler: () => {
                router.back();
              },
            },
            {
              label: 'ОБНОВИТЬ',
              color: 'yellow',
              handler: () => {
                window.location.reload();
              },
            },
          ],
        });
      }
      return Promise.reject(error);
    }
  );

  // for use inside Vue files (Options API) through this.$axios and this.$api

  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api;
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
});

export { api };
